var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.SkinComponent, {
    tag: "component",
    attrs: {
      "code": _vm.code
    },
    on: {
      "save": _vm.validate
    },
    model: {
      value: _vm.board,
      callback: function ($$v) {
        _vm.board = $$v;
      },
      expression: "board"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }