<template>
    <component :is="SkinComponent" v-model="board" :code="code" @save="validate"></component>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        login: { type: Boolean, default: null },
        secret: { type: Boolean, default: null },

        _id: String,
        
        scope: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            board: {
                code: this.$props.code,

                subject: null,
                content: null,
                summary: null,

                password: null,
                thumb: null,

                writer: {
                    name: null,
                },
            },
        };
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            try {
                if (this.login && !this.accessToken) throw new Error("로그인 후 작성 할 수 있습니다");
            } catch (error) {
                console.error(error);
                alert(error.message);

                this.$router.go(-1);
                return;
            }
        },

        validate() {
            try {
                if (this.secret) {
                    if (this.board.password == null || this.board.password.length !== 6) throw new Error("비밀번호는 6자리 숫자여야 합니다.");
                }

                this.save();
            } catch (error) {
                this.handleError(error);
            }
        },

        async save() {
            try {
                var { board } = await api.v1.boards.post({ ...this.board, password: this.secret ? CryptoAES.encrypt(this.board.password) : undefined });

                if (this.board.thumb) await api.v1.boards.postThumb(board, this.board.thumb);

                alert("저장되었습니다");
                this.$router.go(-1);
            } catch (error) {
                this.handleError(error);
            }
        },

        handleError(error) {
            console.error(error);
            alert(error.repsonse ? error.response.data.message : error.message);
        },
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload || {};
        },
    },
};
</script>
